<template>
    <div id="rp-list">

        <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item label="ID">
                <el-input v-model="form.id" placeholder="ID"></el-input>
            </el-form-item>
            <el-form-item label="发起人ID">
                <el-input v-model="form.userId" placeholder="发起人ID"></el-input>
            </el-form-item>
            <el-form-item label="发起人">
                <el-input v-model="form.email" placeholder="email"></el-input>
            </el-form-item>
            <el-form-item label="期号ID">
                <el-input v-model="form.periodId" placeholder="期号ID"></el-input>
            </el-form-item>
            <el-form-item label="彩种">
                <el-select v-model="form.lotteryType" placeholder="请选择" clearable>
                    <el-option v-for="lotteryType in Object.keys(LOTTERY_TYPE)" :key="lotteryType"
                        :label="LOTTERY_TYPE[lotteryType]" :value="lotteryType">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="page.list" border>
            <el-table-column prop="id" label="ID">
            </el-table-column>
            <el-table-column prop="userId" label="发起人">
                <template v-slot="row">
                    [{{ row.row.userId }}]{{ row.row.email }}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发起时间">
            </el-table-column>
            <el-table-column label="彩种">
                <template v-slot="row">
                    {{ LOTTERY_TYPE[row.row.lotteryType] }}
                </template>
            </el-table-column>
            <el-table-column prop="totalTimes" label="总追号次数">
            </el-table-column>
            <el-table-column prop="leftTimes" label="剩余次数">
            </el-table-column>
            <el-table-column label="操作">
                <template v-slot="row">
                    <el-popover placement="left" title="追号明细" trigger="click" width="850">

                        <el-table :data="row.row.items" border>
                            <el-table-column prop="id" label="ID">
                            </el-table-column>
                            <el-table-column prop="createTime" label="执行时间">
                            </el-table-column>
                            <el-table-column prop="periodId" label="期号ID">
                            </el-table-column>
                            <el-table-column label="状态">
                                <template v-slot="r">
                                    {{ RPI_STATUS[r.row.status] }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="biId" label="投注ID">
                                <template v-slot="r">
                                    <span v-if="r.row.biId != null" @click="goBI(r.row.biId)">{{ r.row.biId }}
                                        (查看)</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button slot="reference">明细</el-button>
                    </el-popover>
                    <el-divider direction="vertical"></el-divider>
                    <el-popover placement="left" title="追号明细" trigger="click" width="800">
                        <Bingo18Content v-if="row.row.lotteryType == 'BINGO18'" :betContent="row.row.betContent">
                        </Bingo18Content>
                        <el-button slot="reference">内容</el-button>
                    </el-popover>
                </template>
            </el-table-column>


        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
import { list } from './rp'
import { LOTTERY_TYPE, RPI_STATUS } from "../../util/constants";
import Bingo18Content from '../../components/Bingo18Content.vue'

export default {
    name: "RepeatPlanList",
    components: {
        Bingo18Content,
    },
    data() {
        return {
            LOTTERY_TYPE,
            RPI_STATUS,

            form: {
                pageNo: 1,
                pageSize: 20,
                lotteryType: null,
                playType: null,
                prizeStatus: null,
                userId: null,
                periodId: null,
                id: null,
                email: null,

            },

            page: {
                list: null,
                totalPage: null,
            },
        };
    },

    methods: {
        loadList() {
            list(this.form).then((data) => {
                this.page = data;
            });
        },

        changePage(val) {
            this.form.pageNo = val;
            this.loadList();
        },

        goBI(biId) {
            this.$router.push({
                path: '/bi-list?id=' + biId,
            });
        },

    },

    created() {
        this.loadList();
    },

};
</script>