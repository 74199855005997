<template>
    <div id="bingo18content">

        <el-table :data="contentList" border :show-header="false">
            <el-table-column>
                <template v-slot="row">
                    {{ PLAY_TYPE[row.row.playType] }}
                </template>
            </el-table-column>
            <el-table-column>
                <template v-slot="row">
                    <el-table :data="row.row.betItems">
                        <el-table-column label="投注值" prop="val">
                            <template v-slot="row2">
                                <span v-if="row.row.playType == 'B18_GDS'">
                                    {{ B18_GDS[row2.row.val]}}
                                </span>
                                <span v-else>
                                    {{ row2.row.val }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="倍数" prop="multiple"></el-table-column>
                        <el-table-column label="金额" prop="amount"></el-table-column>
                        <el-table-column label="奖金" prop="prize">
                            <template v-slot="row2">
                                <span v-if="row2.row.won != null && row2.row.won" class="prize_span">
                                    {{ row2.row.prize }}
                                </span>

                            </template>
                        </el-table-column>

                    </el-table>
                </template>
            </el-table-column>
        </el-table>

    </div>
</template>

<script>
import {  PLAY_TYPE, B18_GDS } from "../util/constants";

export default {
    name: "Bingo18Content",
    props: {
        betContent: {
            type: Object,
            default: null,
        }
    },

    data() {
        return {
            PLAY_TYPE,
            B18_GDS,

            contentList: []
        };
    },

    created() {
        if (this.betContent.m1 != null) {
            this.contentList.push(this.betContent.m1);
        }
        if (this.betContent.m2 != null) {
            this.contentList.push(this.betContent.m2);
        }
        if (this.betContent.m3 != null) {
            this.contentList.push(this.betContent.m3);
        }
        if (this.betContent.total != null) {
            this.contentList.push(this.betContent.total);
        }
        if (this.betContent.gds != null) {
            this.contentList.push(this.betContent.gds);
        }

        // alert('bc:' + this.betContent);
        // console.log('cl:' + this.contentList);
    },

    computed() {

    },
};
</script>
<style scoped>
.prize_span {
    color: #ec4949;
}
</style>